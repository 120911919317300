@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: "Valia";
  src:url("Valia-Truelight.ttf.woff");
}
* {
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar-thumb {
  background-color: #333333;
  background-image: -webkit-linear-gradient(45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #f5f5f5;
}
body {
  background-color: #5a5a5a;
  min-height: 100vh;
  position: relative;
}
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: 3% 97%;
  transition: grid-template-columns linear 0.5s;
}

.App.menuActive {
  grid-template-columns: 12.5% 87.5%;
}

/* ._2kgoq {
  display: none;
} */

/* Login Form */
.login {
  height:100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column: 2 span;
}
.login .form {
  background-color: #ffffff;
  padding: 75px;
  border-radius: 10px;
  width: 400px;
}
.login .form h1 {
  font-size: 46px;
}
.login .form .logo {
  height: 250px;
}
.login .form .logo img {
  height: 100%;
}
.login .form .form-input {
  height:50px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.login .form .form-input input {
  width: 250px;
  height:30px;
  border: none;
  border-bottom: solid 3px #8d8d8d;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
}
.login .form .form-input input:focus-visible {
  outline: none;
}
.login .form button {
  margin: 15px 0;
  width: 250px;
  height:40px;
  border-radius: 50px;
  border: none;
  background-color: #8d8d8d;
  color:white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.login .form p {
  margin-top: 50px;
  font-size: 12px;
}
.login .form p a {
  color:blue;
}
/* End Login Form */

/* Menu */
.menu {
  position: relative;
  background-color: #333333;
  width: 100%;
  height:100vh;
  overflow: hidden;
  box-sizing: border-box;
  padding: 2.5vh 5%;
  color: #ffffff;
}
.menu .header {
  height:10%;
  width: 100%;
  position: relative;
}
.menu .header img {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 50%;
  transition: height linear 0.5s;
}
.App.menuActive .menu .header img {
  height:100%;
}
.menu .nav {
  position: relative;
  margin: 2.3vh 0;
  height:75%;
  border: solid 1px #ffffff;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  overflow-y: auto;
}
.App.menuActive .menu .nav .nav-object {
  grid-template-columns: 20% 73%;
}
.menu .nav .nav-object {
  display: grid;
  height:30px;
  width: 100%;
  overflow: hidden;
  margin: 5px 0;
  grid-template-columns: 100% 0%;
  align-items: center;
  column-gap: 7%;
  box-sizing: border-box;
  color:#ffffff;
  text-decoration: none;
  transition: grid-template-columns linear 0.5s;
}
.menu .nav .nav-object.active {
  color:orange;
  text-decoration: underline;
}
.menu .nav .nav-object .nav-icon,.menu .nav .nav-object .nav-title {
  transition: justify-self linear 0.5s;
}
.App.menuActive .menu .nav .nav-object .nav-icon {
  justify-self: end;
}
.App.menuActive .menu .nav .nav-object .nav-title {
  justify-self: start;
}
.menu .footer {
  height:10%;
  line-height: 40px;
}
.menu .footer h2 {
  width: 0%;
  overflow: hidden;
  transition: width linear 0.5s;
}
.App.menuActive .menu .footer h2 {
  width: 100%;
}
/* End Menu */

/* Page */
.page {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 60px 30px 50px;
  position: relative;
}
.page .list {
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 50px;
  box-sizing: border-box;
  row-gap: 50px;
}
.page .list .list-entity {
  width: 100%;
  overflow: hidden;
  height:auto;
  background-color: #969696;
  box-sizing: border-box;
  padding: 25px 25px 25px 25px;
  border-radius: 15px;
}
.list-entity-field {
  height:26px;
  margin-bottom: 5px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
}
.list-entity-field h4 {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
}
.list-entity-field-display-half {
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  float: left;
}
.list-entity-field-display-classic {
  clear: both;
}
.list-entity-field-image, .list-entity-field-entity-image {
  height:300px;
  background-color: rgba(51, 51, 51, 0.50);
  position: relative;
}
.list-entity-field-image img,.list-entity-field-entity-image img {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.list-entity-field-image svg,.list-entity-field-entity-image svg {
  position: absolute;
  transform: translate(-50%,-50%);
  top:50%;
  left:50%;
  font-size: 100px;
}
.list-entity-field-boolean h4 {
  display: grid;
  grid-template-columns: 4fr 1fr;
  justify-content: center;
  align-items: center;
}
.list-entity-field-boolean h4 input[type="checkbox"] {
  appearance: none;
  display: block;
  height:20px;
  width: 20px;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: #333333;
  border: 2px solid #333333;
  border-radius: 2px;
  display: grid;
  place-content: center;
}
.list-entity-field-boolean h4 input[type="checkbox"]::before {
  content: "";
  width: 0.95em;
  height: 0.95em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: rgb(28, 96, 28);
}
.list-entity-field-boolean h4 input[type="checkbox"]:checked {
  background-color: rgb(47, 161, 47);
  border-color: rgb(28, 96, 28);
}
.list-entity-field-boolean h4 input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.list-entity-field-disabled {
  color:#787878
}
.list-entity-action,.entity-window-action {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2,1fr);
  padding-top: 10px;
}
.list-entity-action-button {
  display: block;
  background-color: transparent;
  border: none;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}
.list-entity-action-button-edit {
  background-color: rgb(47, 161, 47);
}
.list-entity-action-button-delete {
  background-color: rgb(237, 44, 44);
}
.list-entity-action-button-cancel {
  background-color: rgb(235, 152, 0);
}
.list-entity-field-entity-choice label {
  font-weight: bold;
}
.list-entity-field-entity-choice select {
  background-color: transparent;
  border: none;
  color:white;
}
.list-entity-field-entity-choice option {
  background-color: #333333;
}
.list-entity-field-double input,.list-entity-field-title input,.list-entity-field-password input,.list-entity-field-color-string input, .list-entity-field-text textarea {
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-align: center;
  border: solid 1px rgba(51, 51, 51, 0.5);
  border-radius: 10px;
}
.list-entity-field-password button {
  background-color: transparent;
  border: none;
  color:inherit;
}
.list-entity-field-color-string h4 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  justify-content: center;
  align-items: center;
}
.list-entity-field-color-string .color-preview {
  display: inline-block;
  height:30px;
}
.entity-window {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(51, 51, 51, 0.5);
  top:0;
  left:0;
}
.entity-window-close {
  border: none;
  color:#969696;
  background-color: transparent;
  position: absolute;
  top:50px;
  right: 50px;
  font-size: 20px;
  transition: color 0.25s linear;
}
.entity-window-close:hover {
  color:rgb(237, 44, 44);
}
.entity-window-form {
  box-sizing: border-box;
  padding: 50px 25px;
  background-color: #969696;
  width: 500px;
  
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  color:white;
  clear: both;
}
.entity-window-form .list-entity-field-password h4,.entity-window-form .list-entity-field-title h2 {
  width: 100%;
  display: grid;
}
.entity-window-form .list-entity-field-title h2{
  grid-template-columns: 1fr 2fr;
}
.entity-window-form .list-entity-field-password h4{
  grid-template-columns: 2fr 3fr 1fr;
}
.entity-window-form .list-entity-field-color-string h4 {
  grid-template-columns: 2fr 2fr 1fr;
}
.entity-window-form .list-entity-field-image h4 {
  position: absolute;
  bottom: 0;
  top: auto;
  background-color: rgba(51, 51, 51, 0.5);
}
.entity-window-form .list-entity-field-text {
  height: 100px;
}
.entity-window-form .list-entity-field-text textarea {
  height: 95px;
  width: 100%;
  resize: none;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}
.page-action {
  background-color: #787878;
  height: 50px;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  top:0;
  left: 0;
  padding: 10px 50px;
  padding-right: 100px;
  color:white;
  font-size: 20px;
}
.page-action-button {
  border:none;
  background-color: transparent;
  color:inherit;
  font-size: inherit;
}
.page-action-button-new {
  background-color: rgb(47, 161, 47);
  padding: 5px;
  position: absolute;
  top:0;
  right: 0;
  height:100%;
  width: 100px;
}
.page-action-button-new-multiple {
  background-color: rgb(47, 161, 47);
  padding: 5px;
  position: absolute;
  top:0;
  right: 100px;
  height:100%;
  width: 100px;
}
.page-action-button-new.disabled {
  background-color: #333333;
  color:#969696;
}
.page-action-button-new-multiple.disabled {
  background-color: #333333;
  color:#969696;
}
/* End Page */

/* Dashboard */
.dashboard {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(2,1fr);
  column-gap: 25px;
  row-gap: 25px;
  padding: 30px 25px;
  color: white;
}
.section {
  overflow: hidden;
  background-color: #969696;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 15px;
}
.section-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.s2x {
  grid-column: span 2;
}
.s3x {
  grid-column: span 3;
}
.s2y {
  grid-row: span 2;
}
.action-section {
  width: 100%;
  height:50px;
  background-color: #787878;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 15px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.action-section button {
  font-family: inherit;
  font-size: inherit;
  font-weight: bolder;
  color: inherit;
  background-color: transparent;
  border:none;
  border-radius: 15px;
  border: solid 3px white;
  padding: 5px 15px;
  background-color: orange;
}
.pic {
  width: 100%;
  background-color: rgba(51, 51, 51, 0.50);
  padding: 10px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.pic img {
  width: 100%;
}
/* End Dashboard */

/* Contact */
.contact {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 25px;
  row-gap: 25px;
  color:white;
}
.contact .social,.contact .others {
  margin-top: 25px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #787878;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 15px;
}
.contact .action-section {
  margin-top: 25px;
}
.contact .others {
  max-height: 300px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
  justify-content: center;
  align-items: center;
}
.contact .form-mod input {
  width: 80%;
  background-color: transparent;
  border: none;
  border: solid 2px white;
  border-radius: 15px;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
}
.contact .social {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.contact .social .controls {
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
}
.contact .social .controls button {
  border:none;
  background-color: transparent;
  color:inherit;
  font-size: inherit;
  background-color: rgb(47, 161, 47);
  padding: 5px;
  border: solid 2px white;
  border-radius: 5px;
  font-size: 20px;
}
.contact .social .socials-list {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  border: solid 2px white;
  border-radius: 5px;
}
.contact .social .socials-list .row {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  border-top: solid 1px white;
}
.contact .social .socials-list .row>* {
  box-sizing: border-box;
  border-right: solid 1px white;
  padding: 3px 5px;
}
.contact .social .socials-list .row>*:last-child {
  border-right: none;
}
.contact .social .socials-list .row select {
  background-color: transparent;
  border: none;
  color:white;
}
.contact .social .socials-list .row option {
  background-color: #333333;
}
.contact .social .socials-list .title-row {
  border-top: none;
  background-color: #969696;
}
.contact .social .socials-list .row .select {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.contact .social .socials-list .row .link {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.contact .social .socials-list .row .link input {
  width: 100%;
  background-color: #333333;
  border: none;
  border: solid 1px white;
  border-radius: 1px;
  font-family: inherit;
  color: inherit;
  text-align: center;
} 
.contact .social .socials-list .row .action .delete {
  display: block;
  background-color: transparent;
  border: none;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  background-color: rgb(237, 44, 44);
}
/* End Contact */

/* About */
.about {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 25px;
  height: 100%;
}
.about-picture {
  background-color: rgba(51, 51, 51, 0.50);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 9fr 1fr;
  max-height: 100%;
  min-height: 100%;
}
.about-picture .picture {
  height: 100%;
  width: 100%;
  /* max-height: 350px; */
  overflow: hidden;
}
.about-picture .picture img {
  height: 100%;
  position: relative;
  left:50%;
  transform: translate(-50%);
}
.about-picture .form-mod {
  display: grid;
  justify-content: center;
  align-items: center;
  height:100%;
  background-color: #787878;
}
.about-form {
  background-color: #8d8d8d;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 1fr;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
}
.about-form .form-mod {
  background-color: #787878;
  padding: 10px;
  border-radius: 15px;
  height: 100%;
  box-sizing: border-box;
}
.about-form .form-mod textarea {
  width: 100%;
  /* height: 280px; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  background-color: #333333;
  border: none;
  border: solid 1px white;
  border-radius: 10px;
  font-family: inherit;
  color: inherit;
  text-align: center;
  padding: 5px 10px;
  box-sizing: border-box;
}
#inputFile::-webkit-file-upload-button {
  background-color: #333333;
  border: none;
  border: solid 1px white;
  border-radius: 10px;
  font-family: inherit;
  color: inherit;
  text-align: center;
  padding: 5px 10px;
  box-sizing: border-box;
}
/* End About */

/* Footer */
.footer-copy {
  /* background: rgba(40,40,40,0.8);
  background: linear-gradient(0deg, rgba(40,40,40,0.8) 0%, rgba(40,40,40,0.45) 65%, rgba(40,40,40,0) 100%); */
  position: absolute;
  height:3vh;
  width: 100%;
  bottom: 0;
}
.footer-copy-right {
  width: 100%;
  position: absolute;
  bottom: 0.2vh;
  font-size: 1vh;
  color: rgba(177, 177, 177, 0.5);
}
.footer-copy-right a {
  color: rgba(177, 177, 177, 0.5);
}
/* End Footer */

